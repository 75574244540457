<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useElementSize } from "@vueuse/core";
import VBlockLoader from "@magnit/core/src/components/VLoader/VBlockLoader.vue";
import VCookiePolicyPopup from "@magnit/unit-cookie-policy/src/VCookiePolicyPopup.vue";
import VQrLanding from "~/components/VQrLanding.vue";
import VNotificationsManager from "~/components/VNotificationsManager.vue";
import VToastsManager from "~/components/VToastsManager.vue";
import VCardModal from "~/containers/Modals/VCardModal.vue";
import VPwaIosInstructionModal from "~/containers/Modals/VPwaIosInstructionModal.vue";
import { checkMobile } from "~/utils/clientInfo";
import VDigitalReceiptModal from "~/containers/Modals/VDigitalReceiptModal.vue";
import VStorePromptModal from "~/containers/Modals/VStorePromptModal.vue";
import VProductsModal from "~/components/VProductsModal.vue";
import { useUserStore } from "~/store/user";
import { useAuthStore } from "~/store/auth";
import { useSlotsStore } from "~/store/useSlotsStore";

const isMobile = ref(false);
const { storePrompted, storeShopCode } = storeToRefs(useStoresStore());
const authStore = useAuthStore();
const { isMockUser } = storeToRefs(useUserStore());
const { loadSlotApiInit } = useSlotsStore();
const route = useRoute();
const header = ref(null);
const { height } = useElementSize(header);

const marginTop = computed(() => {
  return height.value ? height.value : 0;
});

const showStorePromptModal = computed(
  () => !storePrompted.value && isMobile.value && authStore.state.authorized,
);

const categoriesStore = useCategoriesStore();
await categoriesStore.requestCategories();

const isShowSearch = computed(() => {
  return [
    RouteNames.Catalog,
    RouteNames.CatalogCategory,
    RouteNames.CatalogCategories,
    RouteNames.CatalogCategorySlug,
    RouteNames.CatalogProductSlug,
    RouteNames.CatalogSearch,
  ].includes(route.name as RouteNames);
});

// :TODO временное решение для пушей (форсим отвалившихся ранее, почистить когда-нибудь в декабре)
const pushStore = usePushStore();
const pushToken = useLocalStorage(storage.pushSubscription, "");
const pushPermissionGranted = useLocalStorage(
  storage.pushPermissionGranted,
  "",
);

onMounted(() => {
  loadSlotApiInit();
  isMobile.value = checkMobile();

  // :TODO временное решение для пушей (форсим отвалившихся ранее, почистить когда-нибудь в декабре)
  if (Notification.permission === "granted" && !pushPermissionGranted.value) {
    pushStore.enrollDevice(pushToken.value, true);
    pushPermissionGranted.value = "1";
  }
});

const { displayLoader } = storeToRefs(useAppStore());

watch(storeShopCode, () => categoriesStore.requestCategories(true));
</script>

<template>
  <div
    class="default-layout"
    :class="{
      'is-mobile': isMobile,
      'without-qr-landing': $route.meta.hideQrLanding,
      'with-navbar': !$route.meta.hideNavBar,
    }"
  >
    <div ref="header" class="default-layout__header">
      <VInstallAppBannerLocal />
      <VSearch v-show="isShowSearch" />
    </div>
    <VPwaIosInstructionModal />
    <div
      class="default-layout__page"
      :style="{ ['margin-top']: `${marginTop}px` }"
    >
      <div class="default-layout__page__content">
        <slot />
      </div>
      <client-only>
        <Transition name="fade-down">
          <VNavBar v-if="!$route.meta.hideNavBar" />
        </Transition>
      </client-only>
    </div>
    <VCardModal />
    <VDigitalReceiptModal />
    <VCookiePolicyPopup v-if="!$route.meta.hideCookiePopup" />
    <VQrLanding class="default-layout__qr-landing" />
    <VStorePromptModal v-if="showStorePromptModal" />
    <VNotificationsManager key="app-notifications-manager" />
    <VToastsManager key="app-toast-manager" />
    <VBlockLoader :show="displayLoader" page />
    <VSelectStore />
    <VProductsModal v-if="authStore.state.authorized && isMockUser" />
  </div>
</template>

<style scoped lang="postcss">
.default-layout {
  min-height: 100%;
  min-width: 320px;

  &.with-navbar {
    padding-bottom: var(--navbar-height);
  }

  &__header {
    background: var(--pl-surface-primary-default);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: var(--pl-z-index-overlay);
  }

  &__page {
    height: 100%;
    display: flex;
    flex-direction: column;

    &__content {
      flex-grow: 1;
    }
  }

  &__qr-landing {
    display: none;
  }
}

@media (--pl-viewport-from-m) {
  .default-layout {
    &:not(.is-mobile, .without-qr-landing) {
      .default-layout__header {
        display: none;
      }

      .default-layout__page {
        display: none;
      }

      .default-layout__qr-landing {
        display: flex;
      }
    }
  }
}
</style>
